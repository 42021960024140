import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from 'components/layout'
import SEO from 'components/seo'
import { Container, Row, Col } from 'reactstrap'

const NotFoundPage = ({ data }) => {
  const {
    sanityAppereance: {
      tabs: { menu, footer },
    },
  } = data
  return (
    <Layout navbar={menu} footer={footer}>
      <SEO />
      <section style={{ height: '100vh' }}>
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col>
              <h1>404: Not Found</h1>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const NotFoundQuery = graphql`
  query NotFoundQuery {
    sanityAppereance(sanityId: { eq: "Home" }) {
      ...AppereanceFragment
    }
  }
`

NotFoundPage.defaultProps = {
  data: {},
}

NotFoundPage.propTypes = {
  data: PropTypes.shape(PropTypes.object),
}
